import React from 'react';
import { Link } from 'gatsby';
import Footer from '../components/layout/Footer';
import '../css/tailwind.css';

const Contact = () => (
  <div>
    <header className="sticky top-0 bg-white shadow">
      <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
        <Link className="flex text-2xl" to="/">
          <div className="w-40">
            <img src={'../../../sg-logo.png'} alt="SG Innovations logo" />
          </div>
          {/* <div className="pt-12">SG Innovations </div> */}
        </Link>
        <div className="flex mt-4 sm:mt-0">
          <Link className="px-4" to="/">
            Home
          </Link>
          <Link className="px-4" to="/careers">
            Careers
          </Link>
        </div>
      </div>
    </header>

    <div class="bg-gray-100 font-sans">
      <div class="bg-blue-600 text-white py-8">
        <div class="container mx-auto text-center">
          <h1 class="text-4xl font-semibold">Contact Us</h1>
          <p class="mt-2">We're here to help! (info@sginnovations.com.np)</p>
        </div>
      </div>

      <div class="container mt-8">
        <div class="ml-20 grid grid-flow-col justify-stretch">
          <div></div>
          <div class="bg-white shadow p-6 rounded ">
            <h2 class="text-2xl font-semibold mb-4">Send Us a Message</h2>
            <form
              name="Contact Form"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              action="/"
            >
              <input type="hidden" name="form-name" value="Contact Form" />
              <div class="mb-4">
                <label for="name" class="block text-gray-700 font-medium">
                  Your Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
              <div class="mb-4">
                <label for="email" class="block text-gray-700 font-medium">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
              <div class="mb-4">
                <label for="message" class="block text-gray-700 font-medium">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                ></textarea>
              </div>
              <button
                type="submit"
                class="bg-blue-600 text-white py-2 px-6 rounded hover:bg-blue-700"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

export default Contact;
